import './App.css';
import {useEffect, useState} from "react";

function App() {

    const baseUrl = "https://llittlebear.cn/iapi/link/pick";

    const [jumpUrl, setJumpUrl] = useState(null);

    const getRandomLink = async (params) => {
        let newUrl = jsonToGetParams(params, baseUrl);
        newUrl = newUrl !== '' ? newUrl : baseUrl;
        try {
            const response = await fetch(newUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                // body: JSON.stringify({
                //     // 可以根据需要添加POST请求的数据
                // }),
            });
            if (response.ok) {
                console.log("DATA", response)
                let respData = await response.json();

                if (respData.code === 0) {
                    let data = respData.data;
                    let url = data.url;
                    delete data.url;
                    // const linkUrl = jsonToGetParams(data, url)
                    const linkUrl = respData.data;
                    setJumpUrl(linkUrl)
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    const checkGId = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const paramsObj = {};
        for (const [key, value] of urlParams.entries()) {
            paramsObj[key] = value;
        }
        getRandomLink(paramsObj);
    }

    const jsonToGetParams = (obj, url) => {
        let result = '';
        let item;
        for (item in obj) {
            if (obj[item] && String(obj[item])) {
                result += `&${item}=${obj[item]}`;
            }
        }
        if (result) {
            result = url + '?' + result.slice(1);
        }
        return result;
    }

    useEffect(() => {
        checkGId();
        // getRandomLink();
    }, [])

    return (
        <div className="App">
            <iframe frameBorder={'node'} width={'100%'} height={"100%"} src={jumpUrl}/>
        </div>
    );
}

export default App;
